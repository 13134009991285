






















































import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  useContext,
  useFetch,
  watch
} from '@nuxtjs/composition-api';
import Glide from '@glidejs/glide';
import { useContent } from '~/composables';

export default defineComponent({
  name: 'OurCustomers',
  setup() {
    const { loadBlocks } = useContent();
    const { app: { i18n }, app } = useContext();
    const ourCustomers = ref([]);
    const chunkSize = app.$device.isDesktop || app.$device.isMobile? 6 : 8;
    const chunkedArray = ref([]);
    let glide = null;
    const chunkArray = (array, chunkSize) => {
      const result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
      return result;
    }
    useFetch(async () => {
      const blockData = await loadBlocks({ identifiers: ['our-customers-slider'] });
      if (blockData) {
        ourCustomers.value = JSON.parse(blockData?.[0]?.content);
        chunkedArray.value = chunkArray(ourCustomers.value, chunkSize);
      }
    });
    const initiateGlide = () => {
      glide = new Glide('.glide_customers', {
        type: 'slider',
        rewind: false,
        rewindDuration: 3000,
        startAt: 0,
        perView: 1,
        bound: true,
        gap: 0,
        direction: i18n.locale === 'ar' ? 'rtl' : 'ltr',
        breakpoints: {
          800: {
            perView: 1
          },
          480: {
            perView: 1
          }
        }
      });
      glide?.mount();
    }
    onMounted(() => {
      watch(() => ourCustomers.value, (newVal) => {
        if (glide === null && newVal.length > 0) {
          initiateGlide()
        }
      });
      if (ourCustomers.value.length > 0) {
        initiateGlide()
      }
    })
    onUnmounted(() => {
      if (glide) {
        glide.destroy();
      }
    })
    return {
      ourCustomers,
      chunkedArray
    }
  }
});
